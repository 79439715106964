@import '../../styles/media-queries';

.github-corner {
    position: absolute;
    top: 0;
    right: 0;
    border: 0;

    &:hover .octo-arm {
        animation: octocat-wave 560ms ease-in-out;
    }
    svg {
        fill: var(--color-primary);
        color: var(--color-white);
        transition: var(--transition);

        &:hover {
            fill: var(--color-primary-dark);
        }

        .octo-arm {
            transform-origin: 130px 106px;
        }

        @include mediaQuery(bp-800) {
            height: 8rem;
            width: 8rem;
        }
    }
}
@keyframes octocat-wave {
    0%,
    100% {
        transform: rotate(0);
    }
    20%,
    60% {
        transform: rotate(-25deg);
    }
    40%,
    80% {
        transform: rotate(10deg);
    }
}
@media (max-width: 500px) {
    .github-corner {
        &:hover .octo-arm {
            animation: none;
        }

        .octo-arm {
            animation: octocat-wave 560ms ease-in-out;
        }
    }
}
