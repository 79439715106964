@import '../../styles/media-queries';

.user {
    @include mediaQuery(bp-1800) {
        --grid-width: calc(140rem - (var(--number-of-columns) - 1) * var(--grid-gap));
    }

    display: grid;
    grid-template-columns:
        [full-start] minmax(6rem, 1fr) [center-start] repeat(
            var(--number-of-columns),
            [col-start] minmax(min-content, calc(var(--grid-width) / var(--number-of-columns)))
                [col-end]
        )
        [center-end] minmax(6rem, 1fr) [full-end];
    grid-gap: var(--grid-gap);

    @include mediaQuery(bp-1200) {
        grid-template-columns:
            [full-start] 1fr [center-start] repeat(
                var(--number-of-columns),
                [col-start] minmax(min-content, calc(var(--grid-width) / var(--number-of-columns)))
                    [col-end]
            )
            [center-end] 1fr [full-end];
        grid-gap: var(--grid-gap-mobile);
    }

    @include mediaQuery(bp-600) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    &__section-1,
    &__section-2 {
        padding: var(--grid-gap) 0;
        background-color: var(--color-dark);
        min-height: 50vh;

        @include mediaQuery(bp-600) {
            padding: var(--grid-gap-mobile) var(--grid-gap-mobile);
            grid-gap: var(--grid-gap-mobile);
        }
    }

    &__footer {
        padding: 6rem 2rem 3rem;
        background-color: var(--color-dark);

        @include mediaQuery(bp-1000) {
            padding: 3rem 2rem;
        }
    }
}
