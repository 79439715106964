.error-message {
    color: var(--color-primary-light);
    display: inline-block;
    font-size: 2rem;
    visibility: none;
    opacity: 0;
    transition: all 0.2s;

    &--active {
        visibility: visible;
        opacity: 1;
    }
}
