.spinner-overlay {
    min-height: 50vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.spinner {
    display: inline-block;
    width: 4.5rem;
    height: 4.5rem;
    border-radius: 50%;
    color: var(--color-white-main);
    border: 5px solid var(--color-white-main);
    border-top-color: var(--color-dark-light-2);
    animation: spin 1s ease-in-out infinite;

    &--small {
        width: 30px;
        height: 30px;
    }

    @keyframes spin {
        to {
            -webkit-transform: rotate(360deg);
        }
    }
    @-webkit-keyframes spin {
        to {
            -webkit-transform: rotate(360deg);
        }
    }
}
