@import '../../styles/media-queries';

.home {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--color-dark);
    height: 100vh;

    &__form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        padding: 2rem;
        margin-bottom: 20vh;
        text-align: center;
        height: inherit;

        &__github-icon {
            color: var(--color-primary);
            margin: 2.4vh;
        }

        &__label {
            display: flex;
            flex-direction: column;
            font-size: 4rem;
            font-weight: 400;
            color: var(--color-white-main);
            align-items: center;
        }

        &__input {
            width: 65rem;
            height: 8rem;
            margin: 3.6vh 0 1.5vh;
            padding: 1.5rem;
            font-size: 3rem;
            text-align: center;
            background-color: var(--color-dark-light);
            border-bottom: 3px solid var(--color-dark-light);
            font-family: var(--mono-font-family);
            font-weight: 500;
            outline: 0;
            border: 0;
            color: var(--color-primary-light);
            transition: var(--transition);

            @include mediaQuery(bp-1000) {
                min-width: 40rem;
                width: 60vw;
            }

            &::placeholder {
                font-family: var(--mono-font-family);
                font-weight: 500;
                color: var(--color-primary-light);
                font-size: 2.8rem;
                opacity: 0.5;
            }

            &:focus,
            &:active {
                border: 3px solid var(--color-primary-light);
                background-color: var(--color-dark-darker);

                &::placeholder {
                    visibility: hidden;
                    opacity: 0;
                }
            }

            &--error {
                border: 3px solid var(--color-error);
                &:focus,
                &:active {
                    border: 3px solid var(--color-error);
                }
            }

            &--hide {
                display: none;
            }
        }

        &__button-container {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 3.2vh;

            &--hide {
                display: none;
            }
        }

        &__submit {
            color: var(--color-white);
            background-color: var(--color-primary);
            border-radius: 3rem;
            box-shadow: 0 0.5rem 2rem -1.2rem var(--color-primary);
            border: 2px solid var(--color-primary);
            font-size: 1.8rem;
            font-weight: 600;
            width: 14rem;
            height: 5.8rem;
            letter-spacing: 0.5px;
            transition: var(--transition);
            outline: none;

            &:first-of-type {
                margin-right: 3.2rem;
            }

            &:hover,
            &:focus {
                cursor: pointer;
                box-shadow: 0 0.5rem 2rem -1.2rem var(--color-primary-dark);
                background: var(--color-primary-dark);
                border: 2px solid var(--color-primary-dark);
            }
        }
    }
}
