.footer {
    &__list {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;

        a {
            font-weight: 600;
        }

        &__item {
            &:not(:last-child),
            &:not(:first-child) {
                margin-left: 0.6rem;
            }

            &--link {
                margin-right: 0.6rem;
            }

            &:last-child {
                margin: 0rem;
            }
        }
    }
}
