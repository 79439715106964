@import '../../styles/media-queries';

.repos {
    grid-column: center-start / center-end;
    width: 100%;

    header {
        display: flex;
        align-items: center;
        margin: 0 1rem 3rem 0;
        width: max-content;
    }

    &__title {
        font-size: 2.8rem;
        border-bottom: var(--dashed-line-light);
    }

    &__dropdown-wrapper {
        display: flex;
        align-items: center;

        &__label {
            margin: 0 1rem;
            color: var(--color-grey-light-1);
            font-weight: 300;
        }
    }

    &__dropdown {
        position: relative;
        width: 100px;
        font-size: 1.4rem;

        * {
            font-family: var(--mono-font-family);
            font-size: 1.4rem;
            letter-spacing: 0.01em;
            font-weight: 500;
        }

        &__button {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            line-height: 1;
            text-align: left;
            background-color: var(--color-white);
            border: none;
            padding: 10px 7px;
            border-radius: 5px;
            outline: none;

            transition: var(--transition);

            &:hover,
            &:focus {
                cursor: pointer;
                background: var(--color-primary-light);
                border-color: var(--color-primary-light);
            }

            svg {
                transition: var(--transition);
                max-width: 16px;
                margin-left: 0.5rem;
            }

            &--open {
                background: var(--color-white);
                border-radius: 0;
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;

                svg {
                    transition: var(--transition);
                    transform: rotate(180deg);
                }
            }
        }

        &__list {
            position: absolute;
            overflow: hidden;
            width: 100%;
            z-index: 2;
            transition: var(--transition);
            box-shadow: 0 0.5rem 3rem -1.5rem rgba(0, 0, 0, 0.2);
            opacity: 0;
            visibility: hidden;
            background-color: var(--color-primary-light);

            &:last-child {
                border-bottom-left-radius: 5px;
                border-bottom-right-radius: 5px;
            }

            &__item {
                border-radius: 0;
                transition: var(--transition);

                button {
                    border: 0;
                    background: var(--color-white);
                    padding: 10px 7px;
                    width: 100%;
                    font-size: 1.4rem;
                    font-weight: 500;
                    line-height: 1;
                    text-align: left;

                    &:hover,
                    &:focus {
                        cursor: pointer;
                        background: var(--color-primary-light);
                    }
                }
            }

            &--open {
                opacity: 1;
                visibility: visible;
            }
        }
    }

    &__items {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(35rem, 1fr));
        grid-auto-flow: row;
        grid-gap: var(--grid-gap);
    }
}
