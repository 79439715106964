:root {
    --color-primary: #66a6ff;
    --color-primary-light: #c8e1ff;
    --color-primary-dark: #3d8eff;

    --color-grey-light-1: #e7e4e4;
    --color-grey-light-2: #ddd;
    --color-grey-light-3: #ccc;

    --color-grey-dark-1: #777;
    --color-grey-dark-2: #666;
    --color-grey-dark-3: #333;
    --color-grey-dark-4: #222;

    // --color-dark: #0a192f;
    // --color-dark-light: #172a45;
    // --color-dark-light-2: #6888a5;
    // --color-dark-darker: rgb(15, 23, 31);
    --color-dark: #121f2c;
    --color-dark-light: rgb(36, 53, 73);
    --color-dark-light-2: #6888a5;
    --color-dark-darker: rgb(15, 23, 31);
    // --color-dark: #15202b;
    // --color-dark-light: #253341;
    // --color-dark-light-2: #6888a5;
    // --color-dark-darker: #131d27;

    --color-white: #fff;
    --color-white-main: #cfd9fa;
    --color-white-alternate: #8b96b4;

    --color-link: var(--color-primary);
    --color-error: #ff5252;
}
