@import '../../styles/media-queries';

.stats {
    --chart-container-width: 42rem;

    grid-column: col-start 6 / center-end;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(32rem, 1fr));
    grid-template-rows: repeat(auto-fill, min-content);
    justify-items: center;
    align-items: start;
    justify-content: center;
    align-content: start;
    grid-gap: var(--grid-gap);
    margin-top: 2px;

    @include mediaQuery(bp-1200) {
        grid-gap: var(--grid-gap-mobile);
    }

    @include mediaQuery(bp-600) {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
    }

    &__spinner {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 22rem;
    }

    &__chart {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;

        &__container {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            min-height: 30rem;
            background-color: var(--color-dark-light);
            border-radius: var(--border-radius);
            border: 1px solid var(--color-dark-light);
            padding: 2.5rem 2rem;
            width: 100%;
            max-width: 50rem;
            position: relative;

            @include mediaQuery(bp-600) {
                max-width: 75rem;
                margin-top: var(--grid-gap-mobile);
            }

            &__heading {
                display: inline-block;
                margin-bottom: 3rem;
                border-bottom: var(--dashed-line-light);
                font-size: 2.2rem;
            }
        }
    }
}
