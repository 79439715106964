@import '../styles/media-queries';

*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

html {
    font-size: 62.5%; // 1rem = 10px, 10px/16px = 62.5%
    box-sizing: border-box;

    @include mediaQuery(bp-1200) {
        font-size: 56.25%; //1 rem = 9px, 9/16 = 50%
    }
    @include mediaQuery(bp-800) {
        font-size: 50%; //1 rem = 8px, 8/16 = 50%
    }
}

ul,
ol {
    list-style: none;
}

img {
    width: 100%;
    max-width: 100%;
    vertical-align: middle;
}
